/* Main container styling */
.AboutPage-Container {
    max-width: 1100px;
    margin: 40px auto;
    padding: 20px;
}

/* Section for introduction */
.IntroductionSection {
    margin-bottom: 20px;
}


.CharlesImage {
    float: left; /* Float the image to the left */
    margin-right: 20px; /* Space between image and text */
    max-width: 240px; /* Maximum width for image */
    border-radius: 0;
    position: relative;
    display: flex;
}

.CharlesImage > p {
    font-size: 0.4em !important;
    bottom: 0;
    left: 0;
}

/* Adding a clearfix after the image to prevent text from wrapping around it indefinitely */
.IntroductionSection::after {
    content: "";
    display: table;
    clear: both;
}

.AboutH2 {
    color: #333;
    margin: 0 0 10px;
    font-weight: bold;
    line-height: 1.2; /* Adjusted for better readability */
}

.IntroContent p {
    line-height: 1.3; /* Increased line height for clarity */
    margin: 0;
    margin-bottom: 1em;
}

/* News section styling */
.News {
    max-width: 1100px; /* Match the width of .AboutPage-Container */
    padding-left: 0;
    list-style: none; /* Remove default list styling */
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
}

/* Add paragraph spacing inside News items */
.News p {
    margin: 0 0 1em; /* Add spacing below each paragraph */
    line-height: 1; /* Adjust line height for better readability */
}

.News li {
    padding: 10px 0; /* Space between items */
    border-bottom: 1px solid #ddd; /* Subtle divider between news items */
    margin-bottom: 1em;
    line-height: 1.0;
}

/* Add paragraph spacing inside News items */
.News li .date {
    display: block; /* Ensure the date is on its own line */
    font-weight: bold;
    margin-bottom: 15px; /* Add space between the date and the news content */
    color: #333; /* Darker color for emphasis */
}

.News li:last-child {
    border-bottom: none; /* Remove bottom border from last item */
}

.News strong {
    font-weight: bold;
    color: #333;
    display: inline-block;
    margin-right: 8px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .ImageContainer {
        float: none; /* Remove float on smaller screens */
        margin-right: 0;
        width: 100%; /* Full-width image on smaller screens */
        margin-bottom: 16px;
    }

    .IntroContent {
        overflow: visible; /* Adjust to avoid hidden text */
    }

    .IntroContent h2 {
        font-size: 1.8em; /* Slightly smaller for mobile */
    }

    .IntroContent p {
        font-size: 1em; /* Adjusted for readability */
    }
}
