/* Main container */
.Publications-Container {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center;
    width: 60%;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
}

/* ORCID Link */
.Orcid-Link {
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
}

.Orcid-Link a {
    font-size: 16px;
    color: var(--secondary);
    text-decoration: none;
    font-weight: 600; /* Bold for emphasis */
}

.Orcid-Link a:hover {
    text-decoration: underline;
}

/* Year grouping for publications */
.Publications-Year-Group {
    margin-bottom: 40px;
    width: 100%; /* Ensure full width for year group */
}

.Publication-Year {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #333;
    text-align: left; /* Align the year to the left */
}

/* Full-width publication items */
.Publication-item-full {
    width: 100%;
    padding: 10px;
    border: 1px solid #dadce0;
    background-color: white; /* Light background for separation */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    border-radius: 0; /* Block corners */
    margin-bottom: 20px;
    box-sizing: border-box;
    position: relative; /* Allow absolute positioning for tag */
}

/* Hover effect for publication items */
.Publication-item-full:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

/* Publication header */
.Publication-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

/* Publication title */
.Publication-item-container h2 {
    font-size: 20px; /* Slightly smaller title */
    font-weight: 600;
    color: #333;
    margin: 0 0 10px; /* Add spacing below the title */
    width: 80%; /* Limit the width of the title */
    line-height: 1.1;
}

/* Tag style for type (e.g., Preprint, Journal article) */
.Publication-type {
    font-size: 12px;
    font-weight: 600;
    color: white;
    padding: 4px 8px;
    border-radius: 12px;
    text-transform: uppercase;
    white-space: nowrap;
    position: absolute;
    top: 20px; /* Position the tag in the top right corner */
    right: 20px; /* 20px from the right */
}

/* Tag color for journal articles */
.journal {
    background-color: #4caf50; /* Green for journal articles */
}

/* Tag color for preprints */
.preprint {
    background-color: #ff5722; /* Orange for preprints */
}

/* Paragraph styling */
.Publication-item-container p {
    margin: 5px 0;
    font-size: 14px;
    line-height: 1.1;
    color: #555;
}

/* DOI link */
.Publication-item-container a {
    color: var(--secondary);
    text-decoration: none;
    font-weight: 500;
}

.Publication-item-container a:hover {
    text-decoration: underline;
}

/* Full-width publication list */
.Publications-Container-Items {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0px;
}

/* Responsive styling */
@media (max-width: 768px) {
    .Publications-Container {
        width: 90%; /* Full width on smaller screens */
    }

    .Publication-item-full {
        padding: 15px;
    }

    .Publication-item-container h2 {
        font-size: 18px;
    }

    .Publication-item-container p {
        font-size: 13px;
    }

    .Orcid-Link a {
        font-size: 14px;
    }

    .Publication-type {
        font-size: 10px;
        padding: 3px 6px;
    }

    .Publication-Year {
        font-size: 20px;
    }
}
